import { useGroup } from '@frigade/react';
import React from 'react';

/*
|==========================================================================
| useFrigadeGroup
|==========================================================================
|
| A hook to control Frigade Group,
|
*/

export interface useFrigadeGroupHook {
  /**
   * The group id.
   */
  groupId: string | null;

  /**
   * Set the group id, with properties.
   */
  setGroupIdWithProperties: (
    id: string,
    properties: Record<string, unknown>
  ) => Promise<void>;

  /**
   * Set the group id.
   */
  setGroupId: (id: string) => void;

  /**
   * Capture an event for the user's group.
   */
  trackEventForGroup: (
    event: string,
    properties: Record<string, unknown>
  ) => Promise<void>;
}

/**
 * A wrapper around the Frigade group hook, to prevent re-rendering and tracking null groups.
 */
export const useFrigadeGroup = (): useFrigadeGroupHook => {
  const {
    groupId: frigadeGroupId,
    setGroupId: setFrigadeGroupId,
    track: trackEventForFrigadeGroup,
  } = useGroup();
  /*
  |------------------
  | Data
  |------------------
  */

  const groupId = React.useMemo(() => {
    // If its a guest user, we don't want to track them.
    if (frigadeGroupId?.includes('guest')) {
      return null;
    }
    return frigadeGroupId;
  }, [frigadeGroupId]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const setGroupIdWithProperties = React.useCallback(
    async (id: string, properties: Record<string, unknown>) => {
      await setFrigadeGroupId(id, properties);
    },
    [setFrigadeGroupId]
  );

  const setGroupId = React.useCallback(
    (id: string) => {
      setFrigadeGroupId(id);
    },
    [setFrigadeGroupId]
  );

  const trackEventForGroup = React.useCallback(
    async (event: string, properties: Record<string, unknown>) => {
      if (groupId) {
        trackEventForFrigadeGroup(event, properties);
      }
    },
    [trackEventForFrigadeGroup, groupId]
  );

  return React.useMemo(
    () => ({
      groupId,
      setGroupIdWithProperties,
      setGroupId,
      trackEventForGroup,
    }),
    [groupId, setGroupId, setGroupIdWithProperties, trackEventForGroup]
  );
};
