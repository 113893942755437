import _ from 'lodash';
import posthog from 'posthog-js';

import config from '@stargate/config';
import logger from '@stargate/logger';

posthog.init(config.posthog.apiKey, {
  api_host: config.posthog.instance,
  autocapture: false,
  capture_pageleave: false,
  capture_pageview: false,
  opt_in_site_apps: true,
  enable_recording_console_log: true,
  enable_heatmaps: true,
  mask_all_text: false,
  mask_all_element_attributes: false,
  session_recording: {
    recordBody: true,
    recordCrossOriginIframes: true,
    recordHeaders: true,
    maskAllInputs: false,
    maskCapturedNetworkRequestFn: (request) => {
      if (isBannedUrl(request.name)) {
        logger.warn({
          label: 'posthog',
          message: 'Banned URL detected',
          data: {
            url: request.name,
          },
        });
        return null;
      }

      const req = {
        ...request,
        responseHeaders: allowedHeaders(request.responseHeaders),
        requestHeaders: allowedHeaders(request.requestHeaders),
      };

      return req;
    },
  },
});

/*
|------------------
| Config
|------------------
*/

/**
 * The headers that are allowed to be sent to PostHog.
 */
const ALLOWED_HEADERS = [
  // Request Context
  'Host',
  'From',
  'User-Agent',
  'Referer',
  'Referrer-Policy',

  // Response Context
  'Server',
  'Allow',

  // Conditionals
  'If-Match',
  'If-None-Match',
  'If-Unmodified-Since',
  'If-Modified-Since',
  'ETag',
  'Last-Modified',

  // Content Negotiation
  'Accept',
  'Accept-Language',
  'Accept-Encoding',

  // CORS
  'Access-Control-Request-Headers',
  'Access-Control-Request-Method',
  'Access-Control-Allow-Methods',
  'Access-Control-Allow-Headers',
  'Access-Control-Allow-Credentials',
  'Access-Control-Allow-Origin',
  'Origin',
  'Timing-Allow-Origin',

  // Content Representation
  'Content-Type',
  'Content-Length',
  'Content-Encoding',
  'Content-Language',
  'Content-Range',
  'Content-Location',

  // Security
  'Cross-Origin-Embedder-Policy',
  'Cross-Origin-Opener-Policy',
  'Cross-Origin-Resource-Policy',
  'Content-Security-Policy',
  'Permissions-Policy',

  // Proxies
  'Forwarded',

  // Cache
  'Age',
  'Clear-Site-Data',
  'Cache-Control',
  'Expires',
];

/**
 * URLs that are banned from being sent to PostHog.
 */
const BANNED_URLS = ['auth.joggr.io', 'auth0.com', '/api/prx'];

/*
|------------------
| Filters
|------------------
*/

/**
 * Determines if the URL is banned.
 *
 * @param url The URL to check.
 * @returns True if the URL is banned, false otherwise.
 */
const isBannedUrl = (url: string) => {
  return _.some(BANNED_URLS, (bannedUrl) =>
    prepareCompareValue(url).includes(prepareCompareValue(bannedUrl))
  );
};

/**
 * Filters the request headers to only include the allowed headers.
 *
 * @param headers A Headers object containing the request headers.
 * @returns A Headers object containing only the allowed headers.
 */
const allowedHeaders = (headers?: Headers) => {
  if (_.isNil(headers)) {
    return undefined;
  }
  const preparedAllowedHeaders = _.map(ALLOWED_HEADERS, prepareCompareValue);
  return _.pickBy(headers, (_, key) =>
    preparedAllowedHeaders.includes(prepareCompareValue(key))
  );
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Prepares the value for comparison by converting it to lowercase and trimming it.
 *
 * @param value The value to prepare.
 * @returns The prepared value.
 */
const prepareCompareValue = (value: string) => value.toLowerCase().trim();

/*
|------------------
| Types
|------------------
*/

type Headers = Record<string, string>;

/*
|------------------
| Export
|------------------
*/
export default posthog;
