import React from 'react';

import { useConfig } from '@stargate/hooks';
import {
  type FetchData,
  type FetchMethod,
  fetchUnauthorized,
} from '@stargate/lib/http';

export type FrigadeAPIRoute = 'POST /v1/public/users';

export type FrigadeAPIRequest<
  P extends FetchData | undefined = undefined,
  R = void,
> = (route: FrigadeAPIRoute, payload?: P) => Promise<R>;

/**
 * Wrap the Frigade API in a hook.
 *
 * @returns A hook to interact with the Frigade API.
 */
export const useFrigadeAPI = () => {
  const config = useConfig();

  return React.useMemo(() => {
    return {
      async request<P extends FetchData | undefined = undefined, R = void>(
        route: FrigadeAPIRoute,
        payload: P
      ) {
        const [method, path] = route.split(' ') as [FetchMethod, string];
        const result = await fetchUnauthorized<R>(
          `${config.frigade.apiUrl}${path}`,
          {
            method,
            headers: {
              Authorization: `Bearer ${config.frigade.apiKey}`,
              'Content-Type': 'application/json',
            },
            data: payload,
          }
        );

        return result;
      },
    };
  }, [config]);
};
