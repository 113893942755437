import { usePostHog } from 'posthog-js/react';
import React from 'react';

import type { User } from '@stargate/features/user';
import type { ActiveWorkspace } from '@stargate/features/workspaces';
import { useConfig } from '@stargate/hooks';
import { useFrigadeGroup, useFrigadeUser } from '@stargate/vendors/frigade';

/*
|==========================================================================
| useNightingaleIdentify
|==========================================================================
|
| A hook that identifies the current user to the Nightingale library.
|
*/

export interface UseNightingaleIdentifyHook {
  /**
   * Identifies the current user to the Nightingale library.
   */
  user: (user: User) => Promise<void>;

  /**
   * Groups the current user to the Nightingale library.
   */
  group: (workspace: ActiveWorkspace) => Promise<void>;

  /**
   * Resets the current user to a "guest" (`null`) in the Nightingale library.
   */
  reset: () => Promise<void>;
}

export const useNightingaleIdentify = (): UseNightingaleIdentifyHook => {
  const posthog = usePostHog();
  const frigadeUser = useFrigadeUser();
  const frigadeGroup = useFrigadeGroup();
  const config = useConfig();

  const group = async (workspace: ActiveWorkspace) => {
    posthog.group('workspace', workspace.id, {
      name: workspace.name,
      description: workspace.description,
      environment: config.environment ?? 'unknown',
      application: 'stargate',
    });

    await frigadeGroup.setGroupIdWithProperties(workspace.id, {
      name: workspace.name,
      description: workspace.description ?? null,
      environment: config.environment ?? 'unknown',
      application: 'stargate',
    });
  };

  const user = async (user: User, activeWorkspace?: ActiveWorkspace) => {
    posthog.identify(user.id, {
      email: user.email,
      auth0Id: user.auth0Id,
    });
    await frigadeUser.addProperties({
      email: user.email,
      githubUserName: user.githubUsername ?? null,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    });
    if (activeWorkspace) {
      await group(activeWorkspace);
    }
  };

  const reset = async () => {
    posthog.reset();
  };

  return React.useMemo(() => ({ user, group, reset }), []);
};
