import type * as TypeFest from 'type-fest';
import { create } from 'zustand';

import type { NightingaleStatus } from '../types';

/*
|==========================================================================
| useNightingaleStore
|==========================================================================
|
| A global store, used to manage the .
|
*/

interface State {
  /**
   * The current status, which represents is the library ready to submit events.
   */
  status: NightingaleStatus;

  /**
   * The Error object, if any.
   */
  error: Error | null;
}

interface Actions {
  /**
   * Sets the status of the library.
   *
   * @param status The status of the library.
   */
  setStatus: (status: NightingaleStatus) => void;

  /**
   * Sets the error object.
   *
   * @param error The error object.
   */
  setError: (error: Error | null) => void;
}

export type UseNightingaleStoreStore = TypeFest.Simplify<State & Actions>;

const useStore = create<UseNightingaleStoreStore>((set) => ({
  /*
  |------------------
  | State
  |------------------
  */

  error: null,
  status: 'idle',

  /*
  |------------------
  | Actions
  |------------------
  */

  setStatus: (status) => {
    set({ status });
  },
  setError: (error) => {
    set({ error });
  },
}));

/**
 * Hook for managing the
 *
 * @returns A tuple containing the state and actions of the store.
 */
export const useNightingaleStore = (): [State, Actions] => {
  return useStore((store) => [
    {
      status: store.status,
      error: store.error,
    },
    {
      setStatus: store.setStatus,
      setError: store.setError,
    },
  ]);
};
